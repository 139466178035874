import React from 'react';

import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { SearchForm, useSearch, useFilters } from '../components/Filters';

import { PageBody } from '../components/Page';
import { Trainings } from '../components/Trainings';
import { Training, FluidImage } from '../components/Trainings/types';

interface Props {
  data: {
    prismic: {
      allCatalogs: {
        edges: Array<{
          node: {
            header_imageSharp: FluidImage;
            header_title: string;
          };
        }>;
      };
      allTrainings: {
        edges: Array<{
          node: {
            internal_name: string;
            training_pictureSharp: FluidImage;
            tms_data: {
              id: string;
            };
          };
        }>;
      };
    };
    crpm: {
      allTrainings: Training[];
    };
  };
}

export default function Catalog({ data: { prismic, crpm } }: Props) {
  const searchOptions = {
    data: crpm.allTrainings,
    id: 'id',
    indexes: ['name'],
    order: 'name',
  };

  const { searched, query, updateQuery } = useSearch(searchOptions);
  const { filtered } = useFilters(searched);
  const { node: catalog } = prismic.allCatalogs.edges[0];

  const augmented =
    filtered &&
    filtered.map(({ id, ...training }) => ({
      id,
      ...training,
      ...(
        prismic.allTrainings.edges.find(
          ({ node }) => node.tms_data.id === id,
        ) || { node: {} }
      ).node,
    }));

  return (
    <Layout
      callToAction={
        <SearchForm maxWidth={604} onSubmit={updateQuery} query={query} />
      }
      headerAlign="center"
      heroImage={catalog.header_imageSharp}
      subtitle=""
      title={catalog.header_title}
      variant="small"
    >
      <>
        <Seo title={'Catalogue de formations'} />
        <PageBody>
          <Trainings trainings={augmented} />
        </PageBody>
      </>
    </Layout>
  );
}

export const query = graphql`
  query {
    crpm {
      allTrainings {
        id
        name
        code
        slug
        category {
          active
          code
          name
          description
        }
      }
    }
    prismic {
      allTrainings {
        edges {
          node {
            internal_name
            training_picture
            training_pictureSharp {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tms_data
          }
        }
      }
      allCatalogs {
        edges {
          node {
            header_title
            header_image
            header_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
